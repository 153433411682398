<template>
    <div class="main">
        <Base>

        <section class="packs" id="packs-section">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body" style="text-align:justify">
                            <h2> TERMOS E CONDIÇÕES DE USO </h2>

                            <p> Seja bem-vindo ao Calango Doido - colecionável digital NFT. Abaixo são descritos os termos que regem a utilização da coleção e seus respectivos itens.   </p>

                            <p> 1. Os detentores (holders) de NFTs da coleção "Calango Doido" têm permissão para utilizar comercialmente as imagens associadas aos seus respectivos ativos NFT. Isso significa que eles podem usar as imagens para fins comerciais, como, por exemplo, para criar e vender produtos digitais ou físicos que utilizem as imagens.   </p>

                            <p> 2. No entanto, a utilização das imagens associadas aos NFTs da coleção "Calango Doido" é proibida em certos tipos de produtos. Esses produtos incluem, mas não estão limitados a, bonecos, brinquedos, jogos eletrônicos ou analógicos e cartas colecionáveis. Isso significa que os detentores não podem usar as imagens para criar esses tipos de produtos.   </p>

                            <p> 3. O uso associado a conteúdos ofensivos, violentos ou com teor sexual é proibido. Significa que as imagens não podem ser usadas em conjunto com conteúdo que seja considerado ofensivo, violento ou sexualmente explícito, e qualquer violação desta regra pode resultar em medidas legais ou outras ações a serem tomadas pelos detentores da coleção "Calango Doido".  </p>

                            <p> 4. O licenciamento da marca "Calango Doido" não é permitido.  </p>

                            <p> 5. Ao fazer a transferência do NFT, o novo proprietário terá permissão para fazer uso comercial da imagem associada e o antigo dono não poderá mais fazer uso comercial a partir desse momento.   </p>

                            <p> 6. O uso das imagens associadas aos NFTs da coleção "Calango Doido" deve ser feito de acordo com todas as leis e regulamentações aplicáveis, incluindo, mas não limitado a, leis de direitos autorais e propriedade intelectual.   </p>

                            <p> 7. O incumprimento destes Termos de Uso pode resultar em medidas legais ou outras ações a serem tomadas pelos detentores da coleção "Calango Doido", incluindo, mas não limitado a, a revogação da permissão para usar as imagens associadas aos NFTs.   </p>

                            <p> 8. A coleção "Calango Doido" se reserva o direito de modificar estes Termos de Uso a qualquer momento, e os detentores são responsáveis por manter-se atualizados com os Termos de Uso em vigor. </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        </Base>
    </div>
</template>

<script>
import Base from "@/layouts/Base.vue";
export default {
    components: {
        Base,
    },
    methods: {
        choosePack(id) {
        this.$router.push({path: `pagamento?pack=${id}`})
        }
    }
}
</script>

<style scoped>
.packs {
    margin-top: 30px;
}
</style>