<template>
    <div class="main">
        <Base>

        <section class="packs" id="packs-section">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <h2> Política de Privacidade do Calango Doido </h2>
                            <p> O Calango Doido respeita a privacidade de seus usuários e está comprometido em proteger as informações pessoais coletadas. Esta política de privacidade explica como coletamos, usamos e compartilhamos suas informações pessoais. </p>

                        <p> Coleta de Dados: </p>

                        <li> Não armazenamos os dados de CPF dos usuários. Este dado é utilizado apenas para geração do PIX. </li>
                        <li> Não armazenamos as chaves privadas dos usuários. A geração e guarda desta chave é de responsabilidade pessoal do usuário. </li>
                        <li> Os dados de e-mail e nome são utilizados para notificar o usuário sobre transações e confirmações necessárias. </li>

                        <p> Uso de Dados: </p>

                        <li> Utilizamos suas informações pessoais apenas para fornecer e melhorar nossos serviços.
                        <li> Não vendemos ou compartilhamos suas informações pessoais com terceiros sem o seu consentimento.
                        
                        <p> Segurança: </p>

                        <li> Implementamos medidas de segurança para proteger suas informações pessoais contra acesso não autorizado, uso indevido, alteração, destruição ou perda acidental. </li> 
                        
                        <p> Alterações à Política de Privacidade: </p>

                        <li> Reservamos o direito de modificar esta política de privacidade a qualquer momento. Caso façamos alterações significativas, notificaremos os usuários por e-mail ou por meio de um aviso no site. </li>
                        
                        <p>Entre em contato:</p>

                        <li> Se você tiver alguma dúvida sobre nossa política de privacidade, entre em contato conosco pelo e-mail calangodoido@dropday.club </li>
                        </div>                        
                    </div>
                </div>
            </div>
        </section>

        </Base>
    </div>
</template>

<script>
import Base from "@/layouts/Base.vue";
export default {
    components: {
        Base,
    },
    methods: {
        choosePack(id) {
        this.$router.push({path: `pagamento?pack=${id}`})
        }
    }
}
</script>

<style scoped>
.packs {
    margin-top: 30px;
}
</style>