<template>
    <div class="main">
        <Base>
        <section>
            <div class="text-center hero-txt">
                <h1 class="display-4 headline"> DESAFIO <br> <span class="orange"> 10 a 100 </span> </h1>
                <p> Desafio de 10 dias: transformando R$10 em R$100 com criptomoedas! <br> Escolha seu time, torça e acompanhe a evolução! </p>


                <iframe width="560" height="315" src="https://www.youtube.com/embed/w-9yugSXSyY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </section>
        
        <section class="packs" id="packs-section">
            <div class="row">
                <hr><h1 class="display-4 headline text-center"> CONHEÇA OS <br> <span class="orange"> PARTICIPANTES </span> </h1>

                <div class="col-md-6">
                    <div class="card">
                        <img src="/images/1.png" class="image-pack" alt="">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card">
                        <img src="/images/2.png" class="image-pack" alt="">

                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card">
                        <img src="/images/3.png" class="image-pack" alt="">

                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card">
                        <img src="/images/4.png" class="image-pack" alt="">

                    </div>
                </div>
            </div>
        </section>

        <section class="community">
            <div class="row">
                <div class="col-md-12 text-center">
                    <h2>Mantenha-se informado e interaja!</h2>
                    <p>Acompanhe a evolução diária dos participantes na nossa comunidade!</p>

                    <a href="https://discord.gg/aQjcFJsCJu" class="community-btn bt-discord bnt btn-lg btn-primary"> <span class="fab fa-discord"></span> DISCORD </a>
                    <a href="https://t.me/calangoDoido" class="community-btn bt-telegram bnt btn-lg"> <span class="fab fa-telegram"></span> TELEGRAM </a>
                </div>
            </div>
        </section>

        <section>
            <div class="row">
                <div class="col-md-12 text-center">
                    <p>
                        <span> Calango Doido &bullet; CLNG 2013 </span> &bullet; Todos os direitos reservados
                     </p>
                </div>
            </div>
        </section>

        </Base>
    </div>
</template>

<script>
import Base from "@/layouts/Base.vue";
export default {
    components: {
        Base,
    },
    methods: {
        choosePack(id) {
        this.$router.push({path: `pagamento?pack=${id}`})
        }
    }
}
</script>

<style scoped>
.partners, .community, .club {
    padding: 7rem 0;
}
.swag, .moviment { padding: 2rem 10px; }
.partners h2 {
    padding-bottom: 50px;
}
.partners img {
    width: 100px;
    margin: 10px auto;
    filter: grayscale(1);
}

.partners .card {
    background-color: transparent;
    border: none;
}

.community-btn {
    margin: 10px;
}

.community-btn {
    border: 1px solid #E3E3E3;
    text-decoration: none;
}

.bt-telegram:hover {
    background-color: #F3F3F3;
    color: #36b5f0;
    text-decoration: none;
}

.club {
    background-color: #000;
    border-radius: 7px;
    padding: 30px;
}

</style>